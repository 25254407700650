<template>
  <layout-header :images="images" />
  <div class="culture w-about">
    <div class="fir-list">
      <ul>
        <li class="fadeInlist even-li show">
          <span class="img show-row1"
            ><img src="../assets/img/office.jpg" alt=""
          /></span>
          <div class="cont-wrap show-row2">
            <p class="tit">我诞生了！</p>
            <span class="line"></span>
            <span class="word"
              >2019年5月，公司总部设立在“杭州小硅谷”的滨江。<br />
              使命：用软件科技实现价值提升。<br />
              愿景：成为国内最佳价值营销服务商。</span
            >
            <span class="en-font"
              >In May 2019, the company's headquarters was established in
              Binjiang, the "Hangzhou Small Silicon Valley".</span
            >
          </div>
        </li>
        <li class="fadeInlist odd-li show">
          <span class="img show-row1"
            ><img src="../assets/img/archives.jpg" alt=""
          /></span>
          <div class="cont-wrap show-row2">
            <p class="tit">我中标了！</p>
            <span class="line"></span>
            <span class="word"
              >成立当年，顺利中标杭州国税二分局档案管理系统研发项目。</span
            >
            <span class="en-font"
              >In the year of its establishment, it successfully won the bid for
              the archives management system research and development project of
              Hangzhou Second National Taxation Bureau.</span
            >
          </div>
        </li>
        <li class="fadeInlist even-li show">
          <span class="img show-row1"
            ><img src="../assets/img/school.jpg" alt=""
          /></span>
          <div class="cont-wrap show-row2">
            <p class="tit">我成长了！</p>
            <span class="line"></span>
            <span class="word"
              >参与浙江省多所高校选课管理系统与智能门禁、琴房管理项目，实现选课与门禁两大系统相结合的成功案例，帮高校解决同时线上灵活选课、选座问题。</span
            >
            <span class="en-font"
              >Participated in the course selection management system and
              intelligent access control and piano room management projects in
              many colleges and universities in Zhejiang Province, realizing a
              successful case of combining the two major systems of course
              selection and access control, and helping universities solve the
              problem of flexible online course selection and seat selection at
              the same time.</span
            >
          </div>
        </li>
        <li class="fadeInlist odd-li show">
          <span class="img show-row1"
            ><img src="../assets/img/team.jpg" alt=""
          /></span>
          <div class="cont-wrap show-row2">
            <p class="tit">我扩张了！</p>
            <span class="line"></span>
            <span class="word"
              >建立新团队正式进入外贸网站、企业官网市场，并投入资源完成Eleven
              CMS研发。</span
            >
            <span class="en-font"
              >Establish a new team to officially enter the foreign trade
              website and corporate official website market, and invest
              resources to complete the research and development of Eleven
              CMS.</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
  <layout-footer />
</template>
<script>
export default {
  data() {
    return {
      images: [{ imageUrl: "banner04.jpg" }],
    };
  },
};
</script>
