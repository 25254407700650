<template>
  <!-- 头部 -->
  <header>
    <div class="w-layout header">
      <a href="/"><img src="../assets/img/logo.png" /></a>
      <div class="nav">
        <div class="pc f-cb">
          <span class="nav extra-nav">实力价值营销服务商</span>
          <span class="line"></span>
          <ul>
            <li><a href="/">首页</a></li>
            <li><a href="about.html">关于我们</a></li>
            <li><a href="contact.html">招贤纳士</a></li>
          </ul>
          <img style="left: auto" src="../assets/img/tel.png" />
          <span style="float: right">0571-56258010</span>
        </div>
        <div @click="menuClick" class="m-btn f-cb">
          <span class="box">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </span>
        </div>
      </div>
    </div>
  </header>
  <div :class="showSubMenu">
    <div class="box-title f-cb">
      <span class="fl title">快捷导航</span>
      <span @click="hiddenMenu" class="fr close"></span>
    </div>
    <div class="box-list">
      <ul>
        <li>
          <a href="/">
            <span class="col-box f-cb">
              <span class="fl tit">首页</span>
              <span class="fr point"></span>
            </span>
          </a>
        </li>
        <li>
          <a href="about.html">
            <span class="col-box f-cb">
              <span class="fl tit">关于我们</span>
              <span class="fr point"></span>
            </span>
          </a>
        </li>
        <li>
          <a href="contact.html">
            <span class="col-box f-cb">
              <span class="fl tit">招贤纳士</span>
              <span class="fr point"></span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="swiper">
    <div class="swiper-wrapper">
      <div v-for="(image, index) in images" :key="index" class="swiper-slide">
        <img :src="require('@/assets/img/' + image.imageUrl)" />
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
import Swiper, { Pagination, Autoplay } from "swiper";
import "../assets/style/swiper.css";
Swiper.use([Pagination, Autoplay]);

export default {
  mounted() {
    new Swiper(".swiper", {
      spaceBetween: 2,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  data() {
  return {
      showSubMenu: 'm-sub-menu'
    };
  },
  methods: {
    menuClick() {
      this.showSubMenu='m-sub-menu show';
    },
    hiddenMenu() {
      this.showSubMenu='m-sub-menu';
    }
  },
  props:['images'],
};
</script>
