<template>
  <div>支付完成</div>
  <div>
    <button @click="openExternalLink">下载模板</button>
  </div>
</template>
<script>
export default {
  methods: {
    openExternalLink() {
      window.location.href =
        "https://www.juchengsoft.com/api/resource/oss/attach?f=单页自适应企业官网模板.zip";
    },
  },
};
</script>
