<template>
  <!-- 案例展示 -->
  <div class="w-layout caselist">
    <div class="wrap-main">
      <div class="case-nav">
        <h3>案例展示</h3>
        <div class="case-sub-nav">
          <ul>
            <li
              v-for="(item, index) in titleList"
              @click="selCase(index)"
              :class="selNum === index ? 'hover' : ''"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="case-list">
        <ul>
          <li v-for="(caseinfo, index) in caseList" :key="index">
            <a class="img-box">
              <img class="bgimg" src="../assets/img/bg.png" />
              <img
                class="case-img"
                :src="require('@/assets/img/case/' + caseinfo.caseimg)"
              />
            </a>
            <div class="cont-box">
              <p class="box-title">
                <a>{{ caseinfo.title }}</a>
              </p>
              <div class="box-sub-title">
                <span> {{ caseinfo.desc }} </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export const defaulList = [
  {
    caseimg: "C01.jpg",
    title: "家电自适应官网",
    url: "/",
    desc: "高端智能生活家电官网自适应网站",
  },
  {
    caseimg: "C02.jpg",
    title: "地坪行业官网",
    url: "/",
    desc: "为宁波玖固新材料有限公司研发的官网",
  },
  {
    caseimg: "C03.jpg",
    title: "工商财会行业官网",
    url: "/",
    desc: "为税小二研发的企业官网",
  },
  {
    caseimg: "T01.jpg",
    title: "珠宝饰品外贸网站",
    url: "/",
    desc: "精品外贸B2C珠宝饰品官网",
  },
  {
    caseimg: "T02.jpg",
    title: "女装服饰外贸网站",
    url: "/",
    desc: "外贸B2C女装服饰自适应网站",
  },
  {
    caseimg: "T03.jpg",
    title: "日化用品外贸网站",
    url: "/",
    desc: "外贸B2C日化用品企业网站",
  },
  {
    caseimg: "O01.jpg",
    title: "私域电商系统",
    url: "/",
    desc: "为伊心花园研发的一套私域电商系统",
  },
  {
    caseimg: "O02.jpg",
    title: "档案管理系统",
    url: "/",
    desc: "为杭州国税二分局研发的档案管理系统",
  },
  {
    caseimg: "O03.jpg",
    title: "琴房管理系统",
    url: "/",
    desc: "为杭州富阳某艺术职高研发的琴房管理系统",
  },
];

export default {
  data() {
    return {
      selNum: 0,
      titleList: [
        {
          title: "全部案例",
        },
        {
          title: "企业官网",
        },
        {
          title: "外贸网站",
        },
        {
          title: "其他网站",
        },
      ],
      caseList: defaulList,
    };
  },
  methods: {
    selCase(index) {
      this.selNum = index;
      if (index == 0) {
        this.caseList = defaulList;
      } else if (index == 1) {
        this.caseList = [
          {
            caseimg: "C01.jpg",
            title: "家电自适应官网",
            url: "/",
            desc: "高端智能生活家电官网自适应网站",
          },
          {
            caseimg: "C02.jpg",
            title: "地坪行业官网",
            url: "/",
            desc: "为宁波玖固新材料有限公司研发的官网",
          },
          {
            caseimg: "C03.jpg",
            title: "工商财会行业官网",
            url: "/",
            desc: "为税小二研发的企业官网",
          },
        ];
      } else if (index == 2) {
        this.caseList = [
          {
            caseimg: "T01.jpg",
            title: "珠宝饰品外贸网站",
            url: "/",
            desc: "精品外贸B2C珠宝饰品官网",
          },
          {
            caseimg: "T02.jpg",
            title: "女装服饰外贸网站",
            url: "/",
            desc: "外贸B2C女装服饰自适应网站",
          },
          {
            caseimg: "T03.jpg",
            title: "日化用品外贸网站",
            url: "/",
            desc: "外贸B2C日化用品企业网站",
          },
        ];
      } else if (index == 3) {
        this.caseList = [
          {
            caseimg: "O01.jpg",
            title: "私域电商系统",
            url: "/",
            desc: "为伊心花园研发的一套私域电商系统",
          },
          {
            caseimg: "O02.jpg",
            title: "档案管理系统",
            url: "/",
            desc: "为杭州国税二分局研发的档案管理系统",
          },
          {
            caseimg: "O03.jpg",
            title: "琴房管理系统",
            url: "/",
            desc: "为杭州富阳某艺术职高研发的琴房管理系统",
          },
        ];
      }
    },
  },
  computed: {
    selNum() {
      return this.selNum;
    },
  },
};
</script>
