<template>
  <layout-header :images="images" />
  <!-- 招贤纳士 -->
  <div class="w-layout contact">
    <div class="wrap-main">
      <div class="contact-content">
        <h3>招贤纳士</h3>
        <div class="job">
          <h4>岗位：产品经理<span>（1.5 ~ 1.8万）</span></h4>
          <ul>
            <h5>职位描述：</h5>
            <li>1、负责产品市场洞察及需求收集；</li>
            <li>2、负责产品对外的价值传递；</li>
            <li>3、构建产品行业销售渠道和通路，提升产品的行业产品影响力，协同销售团队达成产品的销售业绩；</li>
          </ul>
          <h5>职位要求：</h5>
          <ul>
            <li>1、本科及以上学历，计算机、通讯、管理、市场营销类专业；</li>
            <li>
              2、具备互联网行业经验，3年以上产品经理、市场、销售、解决方案、售前工作或咨询经验优先；
            </li>
            <li>
              3、具有出色的行业开拓和沟通协调能力；
            </li>
            <li>
              4、具有创新能力与意识、市场竞争意识、关键突破的能力与意识；
            </li>
            <li>
              5、具备市场敏锐洞察能力，并善于总结形成文档者优先考虑；
            </li>
          </ul>
        </div>
        <div class="job">
          <h4>岗位：项目经理<span>（1.2 ~ 1.8万）</span></h4>
          <ul>
            <h5>职位描述：</h5>
            <li>1、根据产品规划，分析产品需求，设置项目角色，制定项目计划，指导项目开发；</li>
            <li>2、项目过程中协调各项资源，控制项目进度，并对项目实施过程中的问题、风险进行把控；</li>
            <li>3、项目过程中优化客户业务需求，不断提升客户满意度；</li>
          </ul>
          <h5>职位要求：</h5>
          <ul>
            <li>1、本科及以上学历，工科或管理学科类专业；</li>
            <li>
              2、熟悉研发项目管理流程，熟悉IPD流程，通过PMP认证优先；
            </li>
            <li>
              3、具备良好的沟通/协调能力，善于分析问题查找根因，组织并推动问题解决，具备较强的抗压能力；
            </li>
            <li>
              4、熟练应用Project、甘特图绘制、Office办公软件等项目管理工具；
            </li>
            <li>
              5、具备2年以上管理从业经验、较强的责任心和执行力；
            </li>
          </ul>
        </div>
        <div class="job">
          <h4>岗位：软件工程师<span>（1.2 ~ 1.8万）</span></h4>
          <ul>
            <h5>职位描述：</h5>
            <li>1、负责公司后端研发，确保系统的安全、高可用和可靠性；</li>
            <li>
              2、必要时参与产品需求、设计工作，按时提交成果物、数据模型等；
            </li>
            <li>3、完成领导交办的其它临时性工作；</li>
          </ul>
          <h5>职位要求：</h5>
          <ul>
            <li>1、具备3年及以上Java后端开发相关经验；</li>
            <li>2、具备扎实的Java编程基础；精通Java面向对象设计开发；</li>
            <li>3、对各种开源框架如springcloud、MyBatis等有深入的了解；</li>
            <li>
              4、计算机及相关专业；统招本科及以上，优秀者可放宽至大专学历；
            </li>
            <li>5、有开发网站或应用程序的经验；</li>
            <li>
              6、沟通良好、工作态度端正、积极上进、责任心强，动手能力强，服从工作安排；
            </li>
          </ul>
        </div>
        <div class="job">
          <h4>岗位：网页设计师<span>（1.2 ~ 1.6万）</span></h4>
          <ul>
            <h5>职位描述：</h5>
            <li>1、负责网站或服务平台的页面设计及美观优化；</li>
            <li>2、负责网站或服务平台各类活动的宣传广告、标语的图片设计；</li>
            <li>3、协同其他技术部门，做好美工设计支持；</li>
          </ul>
          <h5>职位要求：</h5>
          <ul>
            <li>1、美术或设计类专业，有3年以上相关工作经验；</li>
            <li>
              2、有良好的美术设计功底，对网页设计的构图、色调、质感、有独到的见解；
            </li>
            <li>
              3、熟悉photoshop、CDR等设计制作软件，基本了解网站架构及互联网知识；
            </li>
            <li>
              4、对网站整体架构有较为清晰的认识，熟悉网站建设的流程和网页设计制作流程；
            </li>
            <li>
              5、沟通良好、工作态度端正、积极上进、责任心强，动手能力强，服从工作安排；
            </li>
          </ul>
        </div>
        <div class="job">
          <h4>岗位：前端工程师<span>（1.2 ~ 1.6万）</span></h4>
          <ul>
            <h5>职位描述：</h5>
            <li>1、负责定制化页面开发；</li>
            <li>2、负责页面UI优化；</li>
            <li>3、负责内部培训网页开发技能；</li>
          </ul>
          <h5>职位要求：</h5>
          <ul>
            <li>
              1、具备3年以上前端开发经验，全日制本科、学信网可查、计算机或相关专业；
            </li>
            <li>
              2、能够深入理解VUE3框架，熟悉组件开发，前后端交互，熟练使用element-plusUI框架；
            </li>
            <li>
              3、精通 JavaScript 语言，熟悉
              TypeScript，熟练使用Html5/CSS3，能根据 UI
              设计高度还原，产出较高性能和交互体验的实现；
            </li>
            <li>
              4、熟悉移动端开发：了解响应式设计和移动端开发的原理和方法，具备移动端开发经验；
            </li>
            <li>
              5、沟通良好、工作态度端正、积极上进、责任心强，动手能力强，服从工作安排；
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <layout-footer />
</template>

<script>
export default {
  data() {
    return {
      images: [{ imageUrl: "banner05.jpg" }],
    };
  },
};
</script>
